import { Clear, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';

export const SearchField = ({
  searchQuery,
  handleSearch,
  handleClearSearch,
}: {
  searchQuery: string,
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void,
  handleClearSearch: () => void,
}) => (
  <TextField
    value={searchQuery}
    onChange={handleSearch}
    label="Search"
    fullWidth
    slotProps={{
      input: {
        startAdornment: (<InputAdornment position="start"><Search /></InputAdornment>),
        endAdornment: searchQuery.length ? (
          <IconButton onClick={handleClearSearch}><Clear /></IconButton>
        ) : null,
      }
    }}
    variant="outlined"
  />
);

import { createContext, useContext, useState, useEffect } from 'react';
import {
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  User,
} from 'firebase/auth';
import { collection, doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';
import { DbUser } from './types';

const provider = new GoogleAuthProvider();
const auth = getAuth();

export const UserContext = createContext<{
  user: User | null;
  dbUser: DbUser | undefined;
  isLoading: boolean;
  isLoggedIn: boolean;
  isEnabled: boolean;
  isAdmin: boolean;
  logIn: () => void;
  logOut: () => void;
}>({
  user: null,
  dbUser: undefined,
  isLoading: true,
  isLoggedIn: false,
  isEnabled: false,
  isAdmin: false,
  logIn: () => { },
  logOut: () => { },
});

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [dbUser, setDbUser] = useState<DbUser | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const logIn = (): void => {
    try {
      signInWithPopup(auth, provider);
    } catch (message) {
      console.error(message);
    }
  };

  const logOut = (): void => {
    try {
      signOut(auth);
    } catch (message) {
      console.error(message);
    }
  };

  useEffect(() => {
    (async () => {
      if (user) {
        setIsLoggedIn(true);

        try {
          const dbUserRef = doc(collection(db, 'users'), user.uid);
          const dbUserSnap = await getDoc(dbUserRef);

          if (dbUserSnap.exists() && dbUserSnap.data().enabled) {
            setIsEnabled(true);
            if (dbUserSnap.data().admin) {
              setIsAdmin(true);
            }
            setDbUser({ ref: dbUserSnap.id, ...dbUserSnap.data() } as DbUser);
          }
        } catch (message) {
          setIsEnabled(false);
          setIsAdmin(false);
        }
      } else {
        setIsLoggedIn(false);
        setIsEnabled(false);
        setIsAdmin(false);
      }
      setTimeout(() => setIsLoading(false), 500);
    })();
  }, [user]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setIsLoading(true);
      setUser(user);
    });
  }, []);

  const context = {
    user,
    dbUser,
    isLoading,
    isLoggedIn,
    isEnabled,
    isAdmin,
    logIn,
    logOut,
  };

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  return context;
};

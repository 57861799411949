import { BrowserRouter, NavLink, Route, Routes } from 'react-router-dom';
import { Box, Container, Link as MLink } from '@mui/material';
import KidsList from './kids-list';
import Footer from './footer';
import Stats from './stats';

const Authorized = () => (
  <Container sx={{ display: 'flex', height: '100%', flexDirection: 'column' }} maxWidth='lg'>
    <Box sx={{ flex: 1 }}>
      <BrowserRouter>
        <Box component='nav' sx={{ display: 'flex', alignItems: 'center', height: '50px' }}>
          <MLink component={NavLink} to='/' underline="hover" sx={{ px: 2, py: 1 }}>Home</MLink>
          <MLink component={NavLink} to='/stats' underline="hover" sx={{ px: 2, py: 1 }}>Stats</MLink>
        </Box>
        <Routes>
          <Route path="/" element={<KidsList />} />
          <Route path="/stats" element={<Stats />} />
        </Routes>
      </BrowserRouter>
    </Box>
    <Footer />
  </Container>
);

export default Authorized;

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';

import { useUserContext } from '../../lib/users-context';
import { useFirebaseContext } from '../../lib/firebase-context';
import { filterKidsByName, findKidsWithMisses } from '../../lib/utils';
import { CheckinDate, Kid } from '../../lib/types';
import { SearchField } from '../search-field';

const ConsecutiveSkips = () => {
  const { dbUser } = useUserContext();
  const { checkInDates, kids, groups } = useFirebaseContext();

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredKids, setFilteredKids] = useState<Kid[]>([]);

  useEffect(() => {
    const filteredBySearchQuery = kids.filter(kid => filterKidsByName(kid, searchQuery));
    setFilteredKids(filteredBySearchQuery);
  }, [kids, searchQuery]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, [setSearchQuery]);

  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
    setFilteredKids(kids);
  }, [setSearchQuery, kids]);

  const kidsData = useMemo(() => {
    if (!checkInDates.length) { return []; }

    return findKidsWithMisses(filteredKids, checkInDates);
  }, [checkInDates, filteredKids]);

  const showGroup = useMemo(() => {
    if (dbUser?.admin) return true;
    return (dbUser?.whitelistedGroups?.length ?? 0) > 1;
  }, [dbUser]);

  const formatAndJoinDates = useCallback((missedDates: CheckinDate['date'][]) =>
    missedDates.map(x => x.toDate().toLocaleDateString(undefined, {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
    })).join(', '), []);

  return (
    <>
      <Box sx={{ display: 'flex', mt: 3 }}>
        <SearchField
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          handleClearSearch={handleClearSearch}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {showGroup && (
                <TableCell>Group</TableCell>
              )}
              <TableCell>Emergency contact</TableCell>
              <TableCell>Number of consecutive skips</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {kidsData.map(kid => (
              <TableRow key={kid.ref} hover>
                <TableCell component="th" scope="row">
                  {kid.last_name} {kid.first_name}
                </TableCell>
                {showGroup && (
                  <TableCell>
                    {groups.find(group => group.ref === kid.group_id)?.group_name}
                  </TableCell>
                )}
                <TableCell>
                  {kid.emergency_contact}
                </TableCell>
                <TableCell>
                  <Tooltip title={formatAndJoinDates(kid.missedDates)}>
                    <Button type='button' variant='text'>{kid.missedDates.length}</Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
};

export default ConsecutiveSkips;

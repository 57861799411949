import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@mui/material';
import { Login, RestartAlt, SupervisorAccount, ViewList } from '@mui/icons-material';
import { arrayRemove, arrayUnion, doc, updateDoc } from 'firebase/firestore';

import { useFirebaseContext } from '../lib/firebase-context';
import { Kid } from '../lib/types';
import { datesCollectionRef } from '../lib/firebase';
import CheckInDate from './check-in-date';
import CheckInDates from './check-in-dates';
import { filterKidsByName } from '../lib/utils';
import { SearchField } from './search-field';

const KidItem = ({ kid, showGroupInfo }: { kid: Kid; showGroupInfo: boolean }) => {
  const { activeCheckInDate, groups } = useFirebaseContext();
  const [isLoading, setIsLoading] = useState(false);
  // TODO, make this better
  const [parentEl, setParentEl] = useState<HTMLButtonElement | null>(null);
  const [groupEl, setGroupEl] = useState<HTMLButtonElement | null>(null);

  const handleParentClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setParentEl(event.currentTarget);
  }, []);

  const handleGroupClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setGroupEl(event.currentTarget);
  }, []);

  const handleParentClose = useCallback(() => {
    setParentEl(null);
  }, []);

  const handleGroupClose = useCallback(() => {
    setGroupEl(null);
  }, []);

  const parentOpen = Boolean(parentEl);
  const groupOpen = Boolean(groupEl);

  const isCheckedIn = useMemo(() =>
    activeCheckInDate?.checkedInRefs?.includes(kid.ref),
    [activeCheckInDate, kid.ref]
  );

  const toggleCheckIn = useCallback(async () => {
    if (!activeCheckInDate) return;
    setIsLoading(true);
    try {
      await updateDoc(doc(datesCollectionRef, activeCheckInDate.ref), {
        checkedInRefs: isCheckedIn ? arrayRemove(kid.ref) : arrayUnion(kid.ref),
      });
    } catch (error) {
      console.error('Error checking in', error);
    }
    setIsLoading(false);
  }, [isCheckedIn, activeCheckInDate, kid.ref]);

  const showParentInfo = useMemo(() => Boolean(kid.emergency_contact), [kid.emergency_contact]);

  const groupName = useMemo(() =>
    groups.find(group => group.ref === kid.group_id)?.group_name || 'No group',
    [kid.group_id, groups]
  );

  return (
    <ListItem sx={({ palette }) => ({ ':hover': { background: palette.action.hover } })}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <Box width={180}>
          {kid.last_name} {kid.first_name}
        </Box>
        {showParentInfo && (
          <>
            <Box sx={{ display: ['none', 'flex'], justifyContent: 'center' }}>
              <Typography component='small' textAlign='center'>
                {kid.emergency_contact}
              </Typography>
            </Box>
            <Box sx={{ display: ['block', 'none'] }}>
              <IconButton aria-describedby={kid.ref} onClick={handleParentClick}>
                <SupervisorAccount />
              </IconButton>
              <Popover
                id={kid.ref}
                open={parentOpen}
                anchorEl={parentEl}
                onClose={handleParentClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <Typography sx={{ p: 2 }}>{kid.emergency_contact}</Typography>
              </Popover>
            </Box>
          </>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {showGroupInfo && (
            <>
              <Typography
                component='small'
                sx={{ width: 200, textAlign: 'center', mr: 2, display: ['none', 'block'] }}
              >
                {groupName}
              </Typography>
              <Box sx={{ display: ['flex', 'none'], mr: 2 }}>
                <IconButton aria-describedby={kid.ref + '_group'} onClick={handleGroupClick}>
                  <ViewList />
                </IconButton>
                <Popover
                  id={kid.ref + '_group'}
                  open={groupOpen}
                  anchorEl={groupEl}
                  onClose={handleGroupClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                >
                  <Typography sx={{ p: 2 }}>{groupName}</Typography>
                </Popover>
              </Box>
            </>
          )}
          {activeCheckInDate && (
            <Button
              variant='outlined'
              disabled={isLoading} size='small'
              sx={{ minWidth: '120px' }}
              onClick={toggleCheckIn}
              startIcon={isCheckedIn ? <RestartAlt /> : <Login />}
              color={isCheckedIn ? 'warning' : 'success'}
            >
              {isCheckedIn ? 'Reset' : 'Present'}
            </Button>
          )}
        </Box>
      </Box>
    </ListItem>
  )
};

const ALL_GROUPS_REF = 'ALL_GROUPS';

const KidsList = () => {
  const { kids, groups } = useFirebaseContext();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredKids, setFilteredKids] = useState<Kid[]>(kids);
  const [groupRef, setGroupRef] = useState(ALL_GROUPS_REF);

  useEffect(() => {
    const filteredByGroup = groupRef === ALL_GROUPS_REF
      ? kids
      : kids.filter(kid => kid.group_id === groupRef);
    const filteredBySearchQuery = filteredByGroup.filter(kid => filterKidsByName(kid, searchQuery));
    setFilteredKids(filteredBySearchQuery);
  }, [kids, searchQuery, groupRef]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, [setSearchQuery]);

  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
    setFilteredKids(kids);
  }, [setSearchQuery, kids]);

  const showGroupInfo = useMemo(() => groupRef === ALL_GROUPS_REF, [groupRef]);

  return (
    <>
      <CheckInDates />
      <CheckInDate />
      <Box sx={{ display: 'flex', mt: 3 }}>
        <SearchField
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          handleClearSearch={handleClearSearch}
        />
      </Box>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="group-select-label">Group</InputLabel>
        <Select
          labelId="group-select-label"
          id="group-select"
          value={groupRef}
          label="Group"
          onChange={(e) => setGroupRef(e.target.value)}
        >
          <MenuItem value={ALL_GROUPS_REF}>All groups</MenuItem>
          {groups.map(group => (
            <MenuItem key={group.ref} value={group.ref}>
              {group.group_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <List>
        {filteredKids.length ?
          filteredKids.map((kid) => (
            <KidItem key={kid.ref} kid={kid} showGroupInfo={showGroupInfo} />
          )) :
          <Typography align='center' sx={{ mt: 2 }}>No results</Typography>
        }
      </List>
    </>
  );
}

export default KidsList;

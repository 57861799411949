import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'
import { Check, DoNotDisturb } from '@mui/icons-material';

import { useFirebaseContext } from '../../lib/firebase-context';
import { Kid } from '../../lib/types';
import { filterKidsByName } from '../../lib/utils';
import { SearchField } from '../search-field';

const StatsByGroup = () => {
  const { checkInDates, kids, groups } = useFirebaseContext();

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredKids, setFilteredKids] = useState<Kid[]>([]);
  const [groupRef, setGroupRef] = useState('');

  useEffect(() => {
    if (groups.length) {
      setGroupRef(groups[0].ref);
    }
  }, [groups]);

  useEffect(() => {
    const filteredByGroup = kids.filter(kid => kid.group_id === groupRef);
    const filteredBySearchQuery = filteredByGroup.filter(kid => filterKidsByName(kid, searchQuery));
    setFilteredKids(filteredBySearchQuery);
  }, [kids, searchQuery, groupRef]);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, [setSearchQuery]);

  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
    setFilteredKids(kids);
  }, [setSearchQuery, kids]);

  const dates = useMemo(() =>
    checkInDates
      .filter(({ checkedInRefs }) => checkedInRefs?.length)
      .map(({ date }) => date.toDate().toLocaleDateString(undefined, {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      })),
    [checkInDates]
  );

  const kidsData = useMemo(() =>
    filteredKids.map(kid => ({
      ref: kid.ref,
      name: `${kid.first_name} ${kid.last_name}`,
      checkIns: checkInDates
        .filter(({ checkedInRefs }) => checkedInRefs?.length)
        .map(({ checkedInRefs }) => checkedInRefs?.includes(kid.ref) ?? false),
    })), [filteredKids, checkInDates]);

  return (
    <>
      <Box sx={{ display: 'flex', mt: 3 }}>
        <SearchField
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          handleClearSearch={handleClearSearch}
        />
      </Box>
      <FormControl fullWidth sx={{ my: 2 }}>
        <InputLabel id="group-select-label">Group</InputLabel>
        <Select
          labelId="group-select-label"
          id="group-select"
          value={groupRef}
          label="Group"
          onChange={(e) => setGroupRef(e.target.value)}
        >
          {groups.map(group => (
            <MenuItem key={group.ref} value={group.ref}>
              {group.group_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              {dates.map(date => <TableCell key={date} align="right">{date}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {kidsData.map(kid => (
              <TableRow key={kid.ref} hover>
                <TableCell component="th" scope="row">
                  {kid.name}
                </TableCell>
                {kid.checkIns.map((checkIn, index) => (
                  <TableCell key={index} align="right">
                    {checkIn ? <Check color='success' /> : <DoNotDisturb color='error' />}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default StatsByGroup;

import { CheckinDate, Kid } from './types';

export const filterKidsByName = (kid: Kid, searchQuery: string) => {
  if (!searchQuery) return true;
  return `${kid.first_name} ${kid.last_name}`.toLowerCase().includes(searchQuery.toLowerCase())
};

export const findKidsWithMisses = (kids: Kid[], dates: CheckinDate[]) => {
  const res = kids.reduce((acc, kid) => {
    for (let i = dates.length - 1; i >= 0; i--) {
      if (!dates[i].checkedInRefs?.length) {
        continue;
      }
      if (!dates[i].checkedInRefs?.includes(kid.ref)) {
        if (!acc[kid.ref]) {
          acc[kid.ref] = {
            data: kid,
            missedDates: [dates[i].date],
          }
        } else {
          acc[kid.ref].missedDates.push(dates[i].date);
        }
      } else {
        break;
      }
    }
    return acc;
  }, {} as Record<string, { data: Kid, missedDates: CheckinDate['date'][] }>);

  return Object.values(res)
    .filter(({ missedDates }) => missedDates.length > 1)
    .map(({ data, missedDates }) => ({ ...data, missedDates }));
};

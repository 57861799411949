import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { onSnapshot } from 'firebase/firestore';
import {
  getAllCheckInDatesQuery,
  getAllGroupsQuery,
  getAllKidsQuery,
  getAllTeachersQuery,
  getKidsByGroupsQuery,
} from './firebase';
import { CheckinDate, Kid, Teacher, Group } from './types';
import { useUserContext } from './users-context';

export const FirebaseContext = createContext<{
  kids: Kid[];
  teachers: Teacher[];
  checkInDates: CheckinDate[];
  activeCheckInDate?: CheckinDate;
  groups: Group[];
}>({
  kids: [],
  teachers: [],
  checkInDates: [],
  activeCheckInDate: undefined,
  groups: [],
});

export const FirebaseProvider = ({ children }: { children: React.ReactNode }) => {
  const [kids, setKids] = useState<Kid[]>([]);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [checkInDates, setCheckInDates] = useState<CheckinDate[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);

  const { dbUser, isLoading } = useUserContext();

  useEffect(() => {
    if (isLoading) return;
    const kidsQuery = dbUser?.whitelistedGroups?.length ?
      getKidsByGroupsQuery(dbUser.whitelistedGroups) :
      getAllKidsQuery;
    const removeKidsListener = onSnapshot(kidsQuery, (snapshot) => {
      const res: Kid[] = [];
      snapshot.forEach((doc) => {
        res.push({ ref: doc.id, ...doc.data() } as Kid);
      });
      setKids(res);
    });

    const removeTeachersListener = onSnapshot(getAllTeachersQuery, (snapshot) => {
      const res: Teacher[] = [];
      snapshot.forEach((doc) => {
        res.push({ ref: doc.id, ...doc.data() } as Teacher);
      });
      setTeachers(res);
    });

    const removeCheckInDatesListener = onSnapshot(getAllCheckInDatesQuery, (snapshot) => {
      const res: CheckinDate[] = [];
      snapshot.forEach((doc) => {
        res.push({ ref: doc.id, ...doc.data() } as CheckinDate);
      });
      setCheckInDates(res);
    });

    const removeGroupsListener = onSnapshot(getAllGroupsQuery, (snapshot) => {
      let res: Group[] = [];
      snapshot.forEach((doc) => {
        res.push({ ref: doc.id, ...doc.data() } as Group);
      });
      if (dbUser?.whitelistedGroups) {
        res = res.filter(group => dbUser?.whitelistedGroups?.includes(group.ref));
      }
      setGroups(res);
    });

    return () => {
      removeKidsListener();
      removeTeachersListener();
      removeCheckInDatesListener();
      removeGroupsListener();
    };
  }, [isLoading, dbUser?.whitelistedGroups]);

  const activeCheckInDate = useMemo(() =>
    checkInDates.find(checkInDate => checkInDate.isActive),
    [checkInDates]
  );

  const context = {
    kids,
    teachers,
    checkInDates,
    activeCheckInDate,
    groups,
  };

  return (
    <FirebaseContext.Provider value={context}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebaseContext = () => {
  return useContext(FirebaseContext);
};

import { useState } from 'react';
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { CheckCircleOutline, ExpandMore } from '@mui/icons-material';
import { doc, updateDoc } from 'firebase/firestore';
import { useFirebaseContext } from '../lib/firebase-context';
import { datesCollectionRef } from '../lib/firebase';
import { useUserContext } from '../lib/users-context';

const CheckInDates = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { checkInDates } = useFirebaseContext();
  const { isAdmin } = useUserContext();

  if (!checkInDates) {
    return null;
  }

  const toggleActive = async (ref: string, isActive: boolean) => {
    if (!isAdmin) return;
    setIsLoading(true);
    try {
      await updateDoc(doc(datesCollectionRef, ref), {
        isActive: !Boolean(isActive)
      });
    }
    catch (error) {
      console.error('Error updating check-in date', error);
    }
    setIsLoading(false);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          Check-in dates
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {checkInDates.map(checkInDate => (
            <ListItem key={checkInDate.ref}>
              <Box sx={{ width: 40, display: 'flex' }} >
                {checkInDate.isActive && (
                  <CheckCircleOutline color='success' />
                )}
              </Box>
              <ListItemText
                primary={checkInDate.date.toDate().toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
                secondary={`Checked in: ${checkInDate.checkedInRefs?.length || 0}`}
              />
              {isAdmin && (
                <Button
                  disabled={isLoading}
                  size='small'
                  color={checkInDate.isActive ? 'warning' : 'success'}
                  onClick={() => toggleActive(checkInDate.ref, checkInDate.isActive)}
                >
                  {checkInDate.isActive ? 'Deactivate' : 'Activate'}
                </Button>
              )}
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default CheckInDates;

import { initializeApp } from "firebase/app";
import { collection, getFirestore, orderBy, query, where } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBS2ZTbDHdYTrS-HzA0OwsaYpwvvtNPVyQ",
  authDomain: "gfs-sunday-school.firebaseapp.com",
  projectId: "gfs-sunday-school",
  storageBucket: "gfs-sunday-school.appspot.com",
  messagingSenderId: "331723945351",
  appId: "1:331723945351:web:5e6710f1f727d942588f22"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export const KIDS_COLLECTION = 'Kids';
export const TEACHERS_COLLECTION = 'Teachers';
export const GROUPS_COLLECTION = 'Groups';
export const DATES_COLLECTION = 'dates';

export const kidsCollectionRef = collection(db, KIDS_COLLECTION);
export const teachersCollectionRef = collection(db, TEACHERS_COLLECTION);
export const groupsCollectionRef = collection(db, GROUPS_COLLECTION);
export const datesCollectionRef = collection(db, DATES_COLLECTION);

export const getAllKidsQuery = query(
  kidsCollectionRef,
  where("is_deleted", "==", false),
  orderBy("last_name"),
  orderBy("first_name")
);
export const getKidsByGroupsQuery = (groupsId: string[]) => query(
  kidsCollectionRef,
  where("group_id", "in", groupsId),
  where("is_deleted", "==", false),
  orderBy("last_name")
);
export const getAllTeachersQuery = query(teachersCollectionRef, where("is_deleted", "==", false), orderBy("teacher_name"));
export const getAllCheckInDatesQuery = query(datesCollectionRef, orderBy("date"));
export const getAllGroupsQuery = query(groupsCollectionRef, orderBy("group_name"));

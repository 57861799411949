import ReactDOM from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

import App from './app';
import { FirebaseProvider } from './lib/firebase-context';
import { UserContextProvider } from './lib/users-context';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ThemeProvider theme={darkTheme}>
    <CssBaseline>
      <UserContextProvider>
        <FirebaseProvider>
          <App />
        </FirebaseProvider>
      </UserContextProvider>
    </CssBaseline>
  </ThemeProvider>
);

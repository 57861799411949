import { Alert, Box, Button, CircularProgress, } from '@mui/material';
import { Google } from '@mui/icons-material';
import { useUserContext } from './lib/users-context';
import Authorized from './components/authorized';

const Wrapper = ({ children }: { children: React.ReactNode }) =>
  <Box sx={{ p: 4, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {children}
  </Box>

const App = () => {
  const { isLoading, isLoggedIn, isEnabled, logIn } = useUserContext();

  if (isLoading) {
    return <Wrapper><CircularProgress /></Wrapper>;
  }

  if (!isLoggedIn) {
    return (
      <Wrapper>
        <Button onClick={logIn} variant="contained" startIcon={<Google />}>
          Log in with Google
        </Button>
      </Wrapper>
    );
  }

  if (!isEnabled) {
    return (
      <Wrapper>
        <Alert severity="error">Please contact admin for appoval of your account.</Alert>
      </Wrapper>
    );
  }

  return (
    <Authorized />
  );
};

export default App;

import { useCallback, useState } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import StatsByGroup from './by-group';
import ConsecutiveSkips from './consecutive-skips';

const TabPanel = ({
  children,
  value,
  index,
}: {
  children: React.ReactNode,
  value: number,
  index: number,
}) => (
  <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
    {index === value && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const a11yProps = (index: number) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
})

const Stats = () => {
  const [tab, setTab] = useState(0);

  const handleChange = useCallback((_: any, newValue: number) => {
    setTab(newValue);
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChange}>
          <Tab label="By groups" {...a11yProps(0)} />
          <Tab label="Consecutive skips" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <StatsByGroup />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <ConsecutiveSkips />
      </TabPanel>
    </Box>
  );
};

export default Stats;

import { Box, Button } from '@mui/material';
import packageJson from '../../package.json';
import { useUserContext } from '../lib/users-context';

const version = packageJson.version;

const Footer = () => {
  const { logOut } = useUserContext();
  return (
    <Box component='footer' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 2 }}>
      <small>v{version}</small>
      <Button onClick={logOut} sx={{ mx: 2 }}>Log out</Button>
    </Box>
  );
}

export default Footer;
